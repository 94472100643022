/* Starts at 780px */

.remove-margin {
    margin-top: 0 !important;
}

.Services__Description-Mobile {
    padding: 0 0 3rem;
    background: #ECE9E6;
    background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6);
    background: linear-gradient(to right, #FFFFFF, #ECE9E6);
}

.Services__Description-Mobile__Wrapper {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin: 5px 0;
}

.Services__Description-Mobile__Wrapper a {
    color: white;
    text-decoration: underline;
    opacity: 0.7;
}

.Services__Description-Mobile__Wrapper__Image {
    position: relative;
    font-family: var(--font-quartary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 20px;
    font-weight: 700;
    color: white;
    z-index: 1;
    width: 100%;
    height: 400px;
}

.Services__Description-Mobile__Wrapper__Image__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: black;
    opacity: 0.6;
}

.Services__Description-Mobile__Wrapper__Image__Overlay-cancel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 5em;
    font-size: 24px;
}

.Services__Description-Mobile__Wrapper__Content {
    font-size: 16px;
}

.Services__Description-Mobile__Wrapper__Content > p:last-child {
    text-decoration: underline;
    cursor: pointer;
}

@media (max-width: 600px) {
    .Services__Description-Mobile__Wrapper__Image {
        height: 450px;
        font-size: 18px;
    }

    .Services__Description-Mobile__Wrapper__Image__Overlay-cancel {
        font-size: 20px;
    }

    .Services__Description-Mobile__Wrapper__Content {
        font-size: 13px;
    }
}

@media (max-width: 500px) {
    .Services__Description-Mobile__Wrapper__Image__Overlay-cancel {
        font-size: 18px;
    }
}

@media (max-width: 350px) {
    .Services__Description-Mobile__Wrapper__Image__Overlay-cancel {
        font-size: 16px;
    }

    .Services__Description-Mobile__Wrapper__Image {
        font-size: 16px;
    }

    .Services__Description-Mobile__Wrapper__Content {
        font-size: 12px;
    }
}