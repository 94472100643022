.CompanySecretarial__Overwrite {
    flex-direction: row !important;
}

.CompanySecretarial__Overwrite__Content {
    flex-basis: 50% !important;
}

.CompanySecretarial__Overwrite__Content-flex {
    flex-basis: 50% !important;
}

@media (max-width: 780px) {
    .CompanySecretarial__Overwrite {
        flex-direction: column-reverse !important;
        align-items: center !important;
        justify-content: center !important;
    }
}