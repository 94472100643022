/* General */
.Services__Subpage .ant-btn {
    color: white;
    background-color: var(--brand-color-maroon);
    border-color: var(--brand-color-maroon);
    width: 200px;
    padding: 2em 1em;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Services__Subpage .ant-btn:hover {
    opacity: 0.7;
    color: white;
    background-color: var(--brand-color-maroon);
    border-color: var(--brand-color-maroon);
}

.Services__Subpage span {
    margin-right: 0.3em;
}


/* Services Sub Pages */
.Services__Subpage {
    min-height: 100vh;
    text-align: left;
    position: relative;
}

.Services__Subpage hr {
    margin: 4em 0;
    border: solid 1px #313131;
    height: 1px;
}

.Services__Subpage__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background: black;
    opacity: 0.4;
    z-index: 1;
}

.Services__Subpage__Overlay-cancel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: transparent;
    display: flex;
    flex-direction: row-reverse;
}

.Services__Subpage__Content-flex {
    flex-basis: 35%;
    height: 100%;
    font-size: 50px;
    font-family: var(--font-tertiary);
    color: white;
    word-spacing: 99999px;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Services__Subpage__Content {
    background-color: white;
    flex-basis: 65%;
    padding: 10em 5em 7em 5em;
    opacity: 0.9;
    overflow-y: auto;
}

.Services__Subpage__Content h1 {
    font-size: 40px;
    font-family: var(--font-quartary);
}

@media (max-height: 1000px) {
    .Services__Subpage {
        height: 1000px;
    }
}

@media (max-width: 1800px) {
    .Services__Subpage .ant-btn {
        width: 150px;
    }
    .Services__Subpage__Content h1 {
        font-size: 30px;
    }

    .Services__Subpage__Content-flex {
        font-size: 45px;
    }
    .Services__Subpage__Content {
        font-size: 13px;
    }
}

@media (max-width: 1250px) {
    .Services__Subpage__Content h1 {
        font-size: 30px;
    }

    .Services__Subpage__Content-flex {
        font-size: 40px;
        flex-basis: 40%;
    }
    .Services__Subpage__Content {
        padding-top: 12em;
        font-size: 13px;
        flex-basis: 60%;
        font-size: 12px;
    }
}

@media (max-width: 1000px) {
    .Services__Subpage__Content h1 {
        font-size: 27px;
    }

    .Services__Subpage__Content-flex {
        font-size: 35px;
    }
}

@media (max-width: 780px) {
    .Services__Subpage .ant-btn {
        width: 50%;
    }

    .Services__Subpage {
        min-height: 50px;
        height: 500px;
    }

    .Services__Subpage__Content h1 {
        font-size: 27px;
    }

    .Services__Subpage__Overlay {
        opacity: 0.6;
    }

    .Services__Subpage__Overlay-cancel {
        flex-direction: column-reverse;
    }

    .Services__Subpage__Content-flex {
        font-size: 30px;
        word-spacing: normal;
        padding: 2em;
        flex-basis: 100%;
    }

    .Services__Subpage__Content {
        padding-top: 7em;
        flex-basis: 100%;
        text-align: left;
    }

    .Services__Subpage__Content hr {
        margin: 3em 0;
    }
}

@media (max-width: 600px) {
    .Services__Subpage .ant-btn {
        width: 100%;
    }

    .Services__Subpage__Content h1 {
        font-size: 27px;
    }
    
    .Services__Subpage__Content-flex {
        font-size: 27px;
        word-spacing: normal;
        padding: 2em;
    }

    .Services__Subpage__Overlay {
        opacity: 0.6;
    }
}

@media (max-width: 400px) {
    .Services__Subpage__Content h1 {
        font-size: 22px;
    }

    .Services__Subpage__Content {
        font-size: 11px;
    }
}