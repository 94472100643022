/* Antd Button */
.Services .ant-btn {
    color: white;
    background-color: var(--brand-color-maroon);
    border-color: var(--brand-color-maroon);
    width: 250px;
    padding: 2em 1em;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 1em;
}

.Services .ant-btn:hover {
    opacity: 0.7;
    color: white;
    background-color: var(--brand-color-maroon);
    border-color: var(--brand-color-maroon);
}

/* Main Content */
.Services {
    background: #ECE9E6; 
    background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6); 
    background: linear-gradient(to right, #FFFFFF, #ECE9E6);
}

.Services__Title {
    background: black;
    font-size: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    color: white;
    height: 600px;
    padding: 3rem 5rem 3rem;
}

.Services__Title span {
    text-decoration: underline;
    font-weight: 900;
    font-size: 30px;
    color: var(--brand-color-orange)
}

.Services__Title > p:first-child {
    text-align: center;
    font-family: var(--font-primary);
    color: var(--brand-color-orange);
    font-size: 80px;
}

.Services__Title > p:first-child span {
    margin-left: 1rem;
}

.Services__End {
    font-size: 20px;
    font-family: var(--font-tertiary);
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em 2em 5em 2em;
}

/* Mobile - Width */
@media (max-width: 1250px) {
    .Services__Title {
        font-size: 22px;
        height: 600px;
    }

    .Services__Title > p:first-child {
        font-size: 70px;
    }
}

@media (max-width: 1000px) {
    .Services__Title {
        font-size: 18px;
        height: 580px;
    }

    .Services__Title > p:first-child {
        font-size: 60px;
    }

    .Services__Title span {
        font-size: 23px;
    }
}

@media (max-width: 780px) {
    .Services {
        padding: 0;
    }
    
    .Services__Title {
        align-items: center;
        text-align: center;
        font-size: 16px;
        height: 500px;
    }

    .Services__Title span {
        font-size: 19px;
    }

    .Services__Title > p:first-child {
        font-size: 47px;
    }

    .Services__End {
        font-size: 16px;
        padding: 1em 3em 5em 3em;
    }
}

@media (max-width: 600px) {
    .Services__Title {
        font-size: 16px;
        padding: 0 4rem 3rem;
    }

    .Services__Title > p:first-child {
        font-size: 45px;
    }

    .Services__Title span {
        font-size: 18px;
    }

    .Services__End {
        font-size: 14px;
    }
}

@media (max-width: 400px) {
    .Services__Title {
        font-size: 14px;
        padding: 0 3rem 3rem;
    }

    .Services__Title > p:first-child {
        font-size: 40px;
    }

    .Services__Title span {
        font-size: 18px;
    }

    .Services__End {
        font-size: 13px;
    }
}