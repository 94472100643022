.Footer {
    background: #000000;
    background: -webkit-linear-gradient(to bottom, #313131, #000000);
    background: linear-gradient(to bottom, #313131, #000000);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 3rem 1rem;
    color: white;
    font-family: var(--font-tertiary);
}

.Footer a {
    color: white;
    transition: 0.2s;
}

.Footer a:hover {
    color: white;
    opacity: 0.7;
    transition: 0.2s;
}

.Footer > * {
    margin: 1.5rem 0;
}

.Footer > *:first-child, :last-child {
    margin: 0;
}

.Footer__Social {
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    width: 10%;
}

.Footer__Social > *:nth-of-type(2) {
    margin: 0 1em;
}

.Footer__Horizontal {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    width: 50%;
    text-align: left;
}

.Footer__Horizontal > div > p:first-child {
    opacity: 0.7;
    font-size: 18px;
    text-decoration: underline;
}

.Footer__Horizontal__Quick-links, .Footer__Horizontal__Contact {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Footer__Horizontal__Quick-links > *, .Footer__Horizontal__Contact > * {
    margin: 0.5em 0;
}

@media (max-width: 1250px) {
    .Footer {
        padding: 4rem 1rem;
        font-size: 13px;
    }

    .Footer__Horizontal > div > p:first-child {
        font-size: 16px;
    }
}

@media (max-width: 780px) {
    .Footer {
        padding: 4rem 1rem;
        font-size: 12px;
    }

    .Footer__Horizontal {
        width: 60%;
    }

    .Footer__Horizontal > div > p:first-child {
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .Footer__Horizontal {
        flex-direction: column-reverse;
        align-items: center;
    }

    .Footer__Horizontal__Quick-links {
        margin-top: 1rem;
    }
}