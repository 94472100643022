.Services__Description {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center;
    color: white;
    padding: 1rem 2rem;
}

.Services__Description__Individual {
    border-radius: 15%;
    position: relative;
    flex-basis: 500px;
    font-family: var(--font-quartary);
    display: flex;
    align-items: center;
    justify-content: center;
    height: 500px;
    font-size: 30px;
    font-weight: 700;
    margin: 2em;
    z-index: 1;
}

.Services__Description__Individual__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: black;
    opacity: 0.6;
}

.Services__Description__Individual__Overlay-cancel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 2em 5em;
}

.Services__Description__Individual__Overlay-cancel a {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: white !important;
}

.Services__Description__Individual__Overlay-cancel figcaption a > p:last-child {
    text-decoration: underline;
    cursor: pointer;
}

.Services__Description__Individual__Overlay-cancel figcaption {
    background: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 18px;
    font-family: var(--font-tertiary);
    font-weight: 300;
}

.Services__Description__Individual__Overlay-cancel figcaption a {
    padding: 1em 2em;
}

@media (max-width: 1950px) {
    .Services__Description__Individual {
        flex-basis: 400px;
        height: 400px;
        font-size: 25px;
    }
}

@media (max-width: 1570px) {
    .Services__Description__Individual {
        flex-basis: 350px;
        height: 350px;
        font-size: 22px;
    }
}

@media (max-width: 1250px) {
    .Services__Description__Individual__Overlay-cancel figcaption {
        font-size: 16px;
    }
}

@media (max-width: 1000px) {
    .Services__Description__Individual {
        flex-basis: 285px;
        height: 285px;
        font-size: 18px;
    }

    .Services__Description__Individual__Overlay-cancel figcaption {
        font-size: 13px;
    }
}