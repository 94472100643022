.Navbar {
    height: 80px;
    width: 100%;
    background: white;
    color: black;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 0 0 1rem;
    z-index: 10;
    position: absolute;
    top: 0;
    left: 0;
    font-family: var(--font-secondary);
    font-weight: 700px;
}

.Navbar__Navigation {
    display: flex;
    align-items: center;
    justify-content: center;
    padding-right: 2rem;
}

.Navbar__Navigation a:after {
    display: block;
    content: '';
    border-bottom: solid 1px black;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
}

.Navbar__Navigation a:hover {
    color: black;
}
 
.Navbar__Navigation a:hover:after {
    transform: scaleX(1); transform-origin: 0% 50%;
}

.Navbar__Navigation a {
    margin: 0 2rem;
    color: black;
}

.Navbar__Navigation > a:last-child {
    margin: 0 0 0 2rem;
}

.Navbar__Contact {
    background: var(--brand-color-maroon);
    height: 80px;
    width: 200px;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
}

.Navbar__Contact a {
    color: white;
}

.Navbar__Contact:hover {
    transition: 0.3s;
    opacity: 0.7;
    cursor: pointer;
}

.Navbar__Contact a:hover {
    color: white;
}

.Navbar__Links-Container__text:after {
    display: block;
    content: '';
    border-bottom: solid 1px white;
    transform: scaleX(0);
    transition: transform 250ms ease-in-out;
    transform-origin: 100% 50%;
}
 
