.CTA {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 100%;
    height: 800px;
    text-align: left;
    background: black;
    color: white;
}

.CTA__Inputs {
    flex-basis: 60%;
    padding: 2rem 4rem;
}

.CTA__Inputs__First-section > p:nth-of-type(1) {
    font-size: 18px;
    font-family: var(--font-secondary);
    letter-spacing: 0.05rem;
}

.CTA__Inputs__First-section > p:nth-of-type(2) {
    font-size: 30px;
    font-family: var(--font-primary);
    color: var(--brand-color-green);
}

.CTA__Inputs__Second-section {
    display: flex;
    align-items: center;
    justify-content: center;
    margin:
}

.CTA__Inputs__Second-section > *:nth-of-type(1) {
    margin-right: 1rem;
}

.CTA__Inputs__Second-section > *:nth-of-type(2) {
    margin-left: 1rem
}

.CTA__Inputs__First-section > * {
    margin: 2rem 0;
}

.CTA__Inputs__First-section p {
    margin: 1rem 0;
}

.CTA__Inputs__Second-section > * {
    margin: 2rem 0;
}

.CTA__Image {
    flex-basis: 40%;
}

.CTA__Image img {
    width: 100%;
    height: 800px;
}

.button {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
}

.CTA .ant-btn {
    margin-top: 2rem;
    color: white !important;
    width: 30%;
    background: var(--brand-color-maroon) !important;
    border-color: transparent !important;
    transition: 0.3s;
}

.CTA .ant-btn:hover {
    opacity: 0.7;
    transition: 0.3s;
}

.CTA .ant-btn[disabled] {
    opacity: 0.5;
}

@media (max-width: 1600px) {
    .CTA__Inputs {
        flex-basis: 50%;
    }

    .CTA__Image {
        flex-basis: 50%;
    }   
    
    .CTA .ant-btn {
        width: 100%;
    }
}

@media (max-width: 1250px) {
    .CTA__Inputs {
        flex-basis: 100%;
    }

    .CTA__Image {
        flex-basis: 0%;
        display: none;
    }   
}

@media (max-width: 600px) {
    .CTA__Inputs__Second-section {
        flex-direction: column;
    }

    .CTA__Inputs__Second-section > *:nth-of-type(1) {
        margin-right: 0;
    }
    
    .CTA__Inputs__Second-section > *:nth-of-type(2) {
        margin-left: 0;
    }

    .CTA .ant-input-textarea {
        margin-top: 2rem;
    }
}