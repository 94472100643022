.ExpandMore {
    text-align: center !important;
    display: flex;
    justify-content: center;
}

.ExpandMore i, .ExpandMore svg {
    margin-left: 0.5em;
}

.ExpandMore__Click {
    font-family: var(--font-tertiary);
    font-style: bold;
}
  