.carousel-root {
    margin-top: 8em;
    padding: 6em 5em;
    background: #ECE9E6; 
    background: -webkit-linear-gradient(to right, #FFFFFF, #ECE9E6); 
    background: linear-gradient(to right, #FFFFFF, #ECE9E6);
}

.carousel-status {
    display: none;
}

.control-dots .dot{
    background-color: var(--brand-color-mid-green) !important;
}

.Clients {
    min-height: 100vh;

    background-color: var(--brand-color-white);
}

.Clients__Title {
    font-size: 80px;
    font-family: var(--font-primary);
    color: var(--brand-color-orange);
    padding: 2em 0 1em 0;
    margin-bottom: 0;
    background: black;
    height: 800px;
    display: flex;
    align-items: center;
    justify-content: center;
}

.Clients__Wrapper {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
    align-items: center;
}

.Clients__Wrapper div {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    flex-basis: 20%;
}

.Clients__Wrapper div:hover {
    opacity: 0.5;
    transform: scale(1.3);
    transition: 0.3s;
}

.Clients__Wrapper > * {
    margin: 3em 2em;
}

.Clients__Carousel {
    padding: 2em 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 20px;
}

.Clients__Carousel__Author {
    font-style: italic;
    font-weight: 900;
    letter-spacing: 5px;
    font-size: 16px;
}

@media (max-width: 1250px) {
    .Clients__Title {
        font-size: 70px;
        height: 600px;
    }

    .Clients__Wrapper div {
        flex-basis: 25%;
    }
}

@media (max-width: 1000px) {
    .Clients__Title {
        padding: 3em 0 2em 0;
        font-size: 60px;
        height: 580px;
    }
}

@media (max-width: 780px) {
    .carousel-root {
        margin-top: 0em;
    }
    
    .Clients__Title {
        padding: 2em 0 2em 0;
        min-height: 400px;
    }

    .Clients__Wrapper div {
        flex-basis: 40%;
    }

    .Clients__Wrapper div:hover {
        opacity: 1;
        transform: none;
    }

    .Clients__Carousel {
        font-size: 16px;
    }

    .Clients__Carousel__Author {
        letter-spacing: 4px;
        font-size: 14px;
    }
}

@media (max-width: 600px) {
    .Clients__Title {
        font-size: 50px;
        min-height: 300px;
    }

    .Clients__Carousel {
        font-size: 14px;
    }

    .Clients__Carousel__Author {
        letter-spacing: normal;
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .Clients__Title {
        font-size: 45px;
    }

    .Clients__Carousel {
        font-size: 13px;
    }
}

@media (max-width: 320px) {
    .Clients__Title {
        font-size: 40px;
    }

    .Clients__Carousel {
        font-size: 12px;
    }
}