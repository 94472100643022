/* Antd General */
.Contact .ant-input-textarea {
    width: 100%;
}

.Contact .ant-btn {
    background: var(--brand-color-maroon) !important;
    border-color: transparent !important;
    transition: 0.3s;
    color: white;
    width: 350px;
}

.Contact .ant-btn:hover {
    transition: 0.3s;
    color: white;
    opacity: 0.7;
}

/* Main */
.Contact {
    padding: 10em 5em 5em;
    width: 100%;
    display: flex;
    align-items: flex-start;
    justify-content: center;
    min-height: 100vh;
    background: #ECE9E6;
    background: -webkit-linear-gradient(to left, #FFFFFF, #ECE9E6);
    background: linear-gradient(to left, #FFFFFF, #ECE9E6);
}

.Contact a {
    color: black;
    font-family: var(--font-secondary);
    transition: 0.3s;
}

.Contact a:hover {
    opacity: 0.7;
    color: black;
    transition: 0.3s;
}

.Contact > *:nth-of-type(1) {
    margin-right: 2em;
}

.Contact > *:nth-of-type(2) {
    margin-left: 2em;
}

/* Info & Heading */
.Contact__Title {
    font-size: 70px;
    font-family: var(--font-primary);
    margin-bottom: 0;
}

.Contact__Info {
    flex-basis: 30%;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
}

.Contact__Info__Chips {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
}

.Contact__Info__Chips > * {
    margin: 2em 0;
}

/* Form */
.Contact__Form {
    flex-basis: 70%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}

.Contact__Errors {
    color: red;
    font-family: var(--font-quartary);
    font-weight: 900;
}

.Contact__Inputs {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: flex-start;
    width: 100%;
    border-radius: 15px;
    margin: 0 0 2em 0;
}

.Contact__Inputs > * {
    margin: 2em 0;
}

.Contact__Helper-text {
    margin-top: 2em;
}

@media (max-width: 1800px) {
    .Contact__Title {
        font-size: 65px;
    }
}

@media (max-width: 1250px) {
    .Contact > *:nth-of-type(1) {
        margin-right: 0
    }
    
    .Contact > *:nth-of-type(2) {
        margin-left: 0
    }

    .Contact {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .Contact__Info {
        align-items: center;
    }

    .Contact__Info__Chips {
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 1000px) {
    .Contact__Title {
        font-size: 60px;
    }
}

@media (max-width: 780px) {
    .Contact .ant-btn {
        margin-top: 2em;
        width: 100%;
    }

    .Contact__Title {
        font-size: 55px;
    }

    .Contact {
        padding: 5em 8em 6em 8em;
    }

    .Contact__Helper-text {
        margin-top: 3em;
    }
}

@media (max-width: 600px) {
    .Contact {
        font-size: 11px;
        padding: 5em 4em 6em 4em;
    }

    .Contact__Title {
        font-size: 55px;
    }

    .Contact__Helper-text {
        margin-top: 4em;
    }
}

@media (max-width: 400px) {
    .Contact {
        font-size: 11px;
        padding: 5em 4em 6em 4em;
    }

    .Contact__Title {
        font-size: 40px;
    }

    .Contact__Helper-text {
        margin-top: 4em;
    }
}