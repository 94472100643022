@import url('https://fonts.googleapis.com/css?family=Epilogue');
@import url('https://fonts.googleapis.com/css?family=Oswald');
@import url('https://fonts.googleapis.com/css?family=Montserrat');
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100&display=swap");

:root {
  --brand-color-mid-green: #00a996;
  --brand-color-orange: #f89a25;
  --brand-color-maroon: #f04f23;
  --brand-color-pink: #f8aea4;
  --brand-color-green: #007979;
  --brand-color-white: #f9faf4;
  --font-primary: Oswald;
  --font-secondary: Montserrat;
  --font-tertiary: Epilogue;
  --font-quartary: Raleway;
}

.App {
  position: relative;
  font-family: var(--font-secondary);
  text-align: center;
  color: black;
  background-color: var(--brand-color-white);
  font-size: 14px;
}

.hide {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  max-width: 200px;
}