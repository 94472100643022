/* General */
.Homepage .ant-btn {
    background-color: var(--brand-color-mid-green);
    border-color: var(--brand-color-green);
    color: white;
}

.Homepage .ant-btn:hover {
    background-color: var(--brand-color-mid-green);
    border-color: var(--brand-color-green);
    color: white;
    opacity: 0.8;
}

.Homepage {
    min-height: 100vh;
    position: relative;
    /* overflow: hidden; */
}

.Homepage svg {
    color: white;
    font-size: 30px;
    position: absolute;
    bottom: 30px;
    transition: 0.3s;
}

.Homepage svg:hover {
    bottom: 35px;
    transition: 0.3s;
    opacity: 0.7;
}

/* Video & Overlay */
.Homepage video {
    position: absolute;
    top: 0;
    right: 0;
    height: 100%;
    min-width: 100%;
    object-fit: cover;
    z-index: 1;
}

.Homepage__Overlay {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 2;
    background-color: rgb(0, 0, 0);
    opacity: 0.7;
}

/* Content above overlay */
.Homepage__Content {
    width: 100%;
    height: 100%;
    background: transparent;
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 1em;
}

/* Logo */
.Homepage__Content__Logo {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    margin-bottom: 0rem;
    font-size: 35px;
    font-weight: 900;
    color: white;
}

.Homepage__Content__Logo img {
    width: 200px;
    height: 200px;
}

.Homepage__Content__Logo__Title {
    font-family: var(--font-primary);
    font-size: 50px;
}

.Homepage__Content__Logo__Subtitle {
    font-family: var(--font-secondary);
    font-size: 25px;
    font-weight: 400;
}

/* Headline */
.Homepage__Content__Header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: 30px;
    letter-spacing: 10px;
    font-weight: 900;
    color: white;
    font-family: var(--font-quartary);
    margin-top: 5rem;
}

.Homepage__Content__Headline {
    font-weight: 300;
    letter-spacing: 18px;
    margin-top: 1em;
}

/* Mobile Height */
@media (max-height: 700px) {
    .Homepage {
        height: 700px;
    }
}

/* Mobile Width */
@media (max-width: 1800px) {
    .Homepage .ant-btn {
        font-size: 14px !important;
    }

    .Homepage__Content__Header {
        font-size: 25px;
    }
}

@media (max-width: 1500px) {
    .Homepage__Content__Logo img {
        width: 180px;
        height: 180px;
    }
    
    .Homepage__Content__Logo__Title {
        font-size: 40px;
    }
    
    .Homepage__Content__Logo__Subtitle {
        font-size: 20px;
    }
}

@media (max-width: 1250px) {
    .Homepage .ant-btn {
        font-size: 12px !important;
    }

    .Homepage__Content__Header {
        font-size: 20px;
        letter-spacing: 8px;
    }
}

@media (max-width: 1000px) {
    .Homepage .ant-btn {
        font-size: 12px !important;
    }

    .Homepage__Content__Logo img {
        width: 180px;
        height: 180px;
    }
    
    .Homepage__Content__Logo__Title {
        font-size: 35px;
    }
    
    .Homepage__Content__Logo__Subtitle {
        font-size: 18px;
    }

    .Homepage__Content__Header {
        font-size: 20px;
    }
}

@media (max-width: 780px) {
    .Homepage__Content__Header {
        font-size: 20px;
    }
}

@media (max-width: 600px) {
    .Homepage .ant-btn {
        font-size: 11px !important;
    }

    .Homepage__Content__Logo img {
        width: 150px;
        height: 150px;
    }
    
    .Homepage__Content__Logo__Title {
        font-size: 30px;
    }
    
    .Homepage__Content__Logo__Subtitle {
        font-size: 16px;
    }
    
    .Homepage__Content__Header {
        font-size: 16px;
        letter-spacing: 5px;
    }
}

@media (max-width: 450px) {
    .Homepage__Content__Header {
        font-size: 14px;
        letter-spacing: 4px;
    }

    .Homepage__Content__Logo__Title {
        font-size: 26px;
    }
    
    .Homepage__Content__Logo__Subtitle {
        font-size: 14px;
    }
}