.PrivacyPolicy {
    padding: 10rem 4rem 6rem 4rem;
    text-align: left;
    background: #ECE9E6;
    background: -webkit-linear-gradient(to left, #FFFFFF, #ECE9E6);
    background: linear-gradient(to left, #FFFFFF, #ECE9E6);
}

.PrivacyPolicy h1 {
    text-align: center;
    font-family: var(--font-primary);
    font-size: 60px;
}

.PrivacyPolicy h2 {
    margin-top: 2rem;
}

@media (max-width: 780px) {
    .PrivacyPolicy {
        padding: 4rem 4rem 6rem 4rem;
    }
}