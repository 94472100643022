.NavbarMobile {
    height: 50px;
    position: relative;
    position: absolute;
    top: 0;
    left: 0;
    z-index: 10;
}

.NavbarMobile__Logo {
    position: absolute;
    top: 20px;
    left: 10px;
}

/*Hamburger*/
.bm-burger-button {
    position: fixed;
    width: 24px;
    height: 20px;
    right: 2vh;
    top: 26px;
}

.bm-burger-bars {
    background: #bdc3c7;
}

.bm-cross {
    background: #bdc3c7;
}

.bm-cross-button {
    height: 24px;
    width: 24px;
}

.bm-menu {
    background: var(--brand-color-white);
    padding: 15%;
    font-size: 14px;
}

.bm-menu-wrap {
    position: fixed;
    height: 100%;
    width: 100% !important;
}

.bm-item-list {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: center;
}

.bm-item-list > * {
    margin: 2rem 0 !important;
}

.bm-item-list a {
    color: black;
    transition: 0.2s;
}

.bm-item-list a:hover {
    color: black;
    opacity: 0.7;
    transition: 0.2s;
}
