.HomepageContent__First {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    background: white;
    padding: 4rem 0;
}

.HomepageContent__First__Image {
    width: 100%;
    flex-basis: 40%;
}

.HomepageContent__First__Text {
    flex-basis: 60%;
    text-align: left;
    font-family: var(--font-secondary);
    padding: 4rem 8rem;
}

.HomepageContent__First__Text__Heading {
    font-size: 20px;
}

.HomepageContent__First__Text__Title {
    font-size: 40px;
    color: var(--brand-color-green);
    font-family: var(--font-primary);
}

.HomepageContent__Second {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    text-align: center;
    background: var(--brand-color-white);
    padding: 8rem 6rem;
}

.HomepageContent__Third {
    overflow-x: hidden;
}

.HomepageContent__Third__Container {
    position: relative;
    height: 400px;
    transition: 0.3s;
}

.HomepageContent__Third__Container:nth-of-type(1) {
}

.HomepageContent__Third__Container:hover {
    opacity: 0.8;
    transition: 0.3s;
}

.HomepageContent__Third__Container__Overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2;
    background: black;
    opacity: 0.6;
}

.HomepageContent__Third__Container__Overlay-cancel {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 2em 5em;
    color: white;
    font-family: var(--font-secondary);
    font-size: 27px;
}

.HomepageContent__Third__Container__Overlay-cancel a {
    text-decoration: underline;
    color: white;
    font-size: 16px;
}

.HomepageContent .ant-btn {
    margin-top: 2rem;
    color: white !important;
    width: 20%;
    background: var(--brand-color-maroon) !important;
    border-color: transparent !important;
    transition: 0.3s;
}

.HomepageContent .ant-btn:hover {
    opacity: 0.7;
    transition: 0.3s;
}

.full-width {
    flex: 0 0 100% !important;
    max-width: 100% !important;
}

@media (max-width: 2000px) {
    .HomepageContent .ant-btn {
        width: 40%;
    }
}

@media (max-width: 1250px) {
    .HomepageContent__First__Image {
        flex-basis: 70%;
    }
    
    .HomepageContent__First__Text {
        flex-basis: 60%;
        padding: 4rem 4rem;
    }

    .HomepageContent__First__Text__Title {
        font-size: 30px;
    } 

    .HomepageContent__Second {
        padding: 6rem 4rem;
    }

    .HomepageContent .ant-btn {
        /* font-size: 14px; */
        width: 100%;
    }

    .HomepageContent__Third__Container__Overlay-cancel {
        font-size: 20px;
    }

    .HomepageContent__Third__Container__Overlay-cancel a {
        font-size: 14px;
    }
}

@media (max-width: 1000px) {
    .HomepageContent__First {
        display: flex;
        flex-direction: column;
        justify-content: center;
    }

    .HomepageContent__First__Image {
        flex-basis: 100%;
    }
    
    .HomepageContent__First__Text {
        flex-basis: 100%;
    }
}

@media (max-width: 600px) {
    .HomepageContent__First__Image img {
        width: 100%;
    }

    .HomepageContent__First__Text__Title {
        font-size: 25px;
    } 

    .HomepageContent__First__Text {
        font-size: 14px;
    }
    
    .HomepageContent__First__Text__Heading {
        font-size: 20px;
    }

    .HomepageContent__Second {
        padding: 4rem 4rem;
    }

    .HomepageContent__Third__Container__Overlay-cancel {
        font-size: 18px;
    }

    .HomepageContent__Third__Container__Overlay-cancel a {
        font-size: 12px;
    }
}

@media (max-width: 400px) {
    .HomepageContent__First__Text__Title {
        font-size: 20px;
    } 

    .HomepageContent__First__Text {
        font-size: 12px;
    }
    
    .HomepageContent__First__Text__Heading {
        font-size: 16px;
    }

    .HomepageContent__Second {
        padding: 4rem 2rem;
    }
}