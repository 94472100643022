.Loader {
    position: fixed;
    top: 0;
    left: 0;
    z-index: 50 !important;
    background: #000000;
    background: -webkit-linear-gradient(to right, #434343, #000000);
    background: linear-gradient(to right, #434343, #000000);   
    height: 100vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: var(--brand-color-white);
    font-family: var(--font-quartary);
    font-weight: 600;
    font-size: 18px;
}

.Loader__Icon {
    position: relative;
}

.Loader img {
    position: absolute;
    left: 50%;
    transform: translate(-50%, -15%);
    width: 120px;
}