/* Antd Tabs */
.About .ant-tabs-tab {
    color: var(--brand-color-green);
}

.About .ant-tabs-tab:hover {
    color: var(--brand-color-mid-green) !important;
}

.About .ant-tabs-tab-active > * {
    color: var(--brand-color-green) !important;
}

.About .ant-tabs-ink-bar {
    background-color: var(--brand-color-mid-green) !important;
}

/* Overwrite Antd Button */
.About__Button {
    padding: 2em 0 !important;
    display: flex !important;
    align-items: center !important;
    justify-content: center !important;
    width: 20%;
    margin-top: 5em !important;
    color: white !important;
    background: var(--brand-color-maroon) !important;
    border-color: transparent !important;
    transition: 0.3s;
}

.About__Button:hover {
    opacity: 0.8;
    transition: 0.3s;
}

/* Intro */
.About__Intro {
    height: 600px;
    position: relative;
    z-index: 1;
}

.About__Intro__Overlay {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: black;
    opacity: 0.5;
}

.About__Intro__Overlay-cancel {
    padding: 10rem 10rem 3rem;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3;
    text-align: center;
    color: white;
    font-size: 30px;
}

.About__Intro__Overlay-cancel span {
    color: var(--brand-color-orange);
    text-decoration: underline;
}

.About__Intro__Overlay-cancel  > p:first-child {
    font-size: 80px;
    font-family: var(--font-primary);
    color: var(--brand-color-orange);
    text-decoration-thickness: 7px;
    margin-bottom: 0.1em;
}

/* After Intro */
.About__Content {
    color: black;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    text-align: left;
}

.About__Content__Display-flex {
    display: flex;
    background-color: var(--brand-color-white);
}

.About__Content__Display-flex__Image {
    width: 100%;
    flex-basis: 30%;
}

.About__Content__Display-flex__Image img {
    height: 800px;
    width: 700px;
}

/* After Intro - Heading */
.About__Content__Heading {
    background-color: var(--brand-color-white);
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: center;
    padding: 4rem;
    flex-basis: 70%;
}

.About__Content__Heading__Wrapper {
    display: flex;
    flex-direction: column;
    font-size: 20px;
    line-height: 45px;
    margin: 5em 0;
}

.About__Content__Heading__Wrapper > p:first-child {
    font-size: 50px;
}

.About__Content__Heading__Wrapper:nth-of-type(1) > div > p:nth-of-type(1) {
    margin: 2em 0;
}

/* Page Break */
.About__Content__Break {
    height: 400px;
    width: 100%;
    position: relative;
    z-index: 1;
    font-size: 60px;
}

.About__Content__Break__Overlay {
    z-index: 2;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: black;
    opacity: 0.7;
}

.About__Content__Break__Overlay-cancel {
    z-index: 3;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: transparent;
    text-align: center;
    padding: 3rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-family: var(--font-quartary);
    color: var(--brand-color-orange);
    font-weight: 900;
}

/* After Intro - Tabs */
.About__Content__Tabs {
    padding: 7em 10em;
    background-color: var(--brand-color-white);
    overflow: hidden !important;
}
.About__Content__Tabs-text {
    font-size: 20px;
    line-height: 45px;
}

.About__Content__Tabs-text > * {
    margin: 2em 0;
}

.About__Content__Tabs-text > *:first-child, :last-child {
    margin: 0;
}

/* Ending */
.About__Content__End {
    width: 100%;
    background-color: var(--brand-color-white);
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 3rem 5rem;
}

.About__Content__End hr {
    border: 1px solid var(--brand-color-green);
    width: 80%;
}

@media (max-width: 1946px) {
    .About__Content__Display-flex__Image {
        width: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }
}

@media (max-width: 1500px) {
    .About__Content__Display-flex__Image {
        width: 100%;
    }
    
    .About__Content__Display-flex__Image img {
        height: 600px;
        width: 510px;
    }
    
    .About__Content__Heading {
        padding: 4rem;
    }
}

@media (max-width: 1250px) {
    .About__Button {
        padding: 1.5em 0;
        width: 40%;
    }

    .About__Intro {
        height: 600px;
    }
    
    .About__Intro__Overlay-cancel  > p:first-child {
        text-decoration-thickness: 5px;
    }

    .About__Content__Heading__Wrapper {
        font-size: 18px;
    }

    .About__Content__Break {
        font-size: 50px;
    }

    .About__Content__Tabs-text {
        font-size: 18px;
    }

    .About__Content__Display-flex {
        display: flex;
        flex-direction: column;
    }

    .About__Content__Display-flex__Image {
        padding-top: 4rem;
    }

    .About__Content__Display-flex__Image {
        flex-basis: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
    }

    
    .About__Content__Display-flex__Image img {
        height: 700px;
        width: 580px;
    }
    
    .About__Content__Heading {
        padding: 4rem;
        flex-basis: 100%;
    }
}

@media (max-width: 1000px) {
    .About__Intro {
        height: 580px;
    }

    .About__Intro__Overlay-cancel {
        font-size: 30px;
    }
    
    .About__Content__Heading__Wrapper {
        font-size: 17px;
    }
    
    .About__Content__Heading__Wrapper > p:first-child {
        font-size: 40px;
    }

    .About__Content__Break {
        font-size: 45px;
    }

    .About__Content__Tabs-text {
        font-size: 17px;
    }

    .About__Content__Display-flex__Image img {
        height: 550px;
        width: 450px;
    }
}

@media (max-width: 780px) {
    .About .ant-tabs-tab {
        font-size: 14px !important;
    }

    .About__Button {
        width: 80%;
    }

    .About__Intro {
        height: 520px;
    }

    .About__Intro__Overlay-cancel {
        font-size: 26px;
        padding: 1rem 5rem;
        justify-content: center;
    }
    
    .About__Intro__Overlay-cancel  > p:first-child {
        text-decoration-thickness: 4px;
        font-size: 90px;
    }

    .About__Content__Heading {
        padding: 7em 6em 7em;
    }

    .About__Content__Heading__Wrapper {
        font-size: 15px;
        line-height: 42px;
    }
    
    .About__Content__Heading__Wrapper > p:first-child {
        font-size: 38px;
    }

    .About__Content__Break {
        font-size: 40px;
    }

    .About__Content__Tabs {
        padding: 4em 3em;
    }

    .About__Content__Tabs-text {
        font-size: 15px;
        line-height: 42px;
    }

    .About__Content__Display-flex__Image img {
        height: 440px;
        width: 370px;
    }
}

@media (max-width: 600px) {
    .About .ant-tabs-tab {
        font-size: 12px !important;
    }

    .About__Button {
        font-size: 13px !important;
    }

    .About__Intro__Overlay-cancel {
        font-size: 22px;
        align-items: center;
        text-align: center;
    }
    
    .About__Intro__Overlay-cancel  > p:first-child {
        text-decoration-thickness: 3px;
        font-size: 70px;
    }

    .About__Content__Heading__Wrapper {
        font-size: 14px;
        line-height: 33px;
    }
    
    .About__Content__Heading__Wrapper > p:first-child {
        font-size: 30px;
    }

    .About__Content__Break {
        font-size: 35px;
    }

    .About__Content__Tabs {
        padding: 4em 2em;
    }

    .About__Content__Tabs-text {
        font-size: 12px;
        line-height: 33px;
    }

    .About__Content__Display-flex__Image img {
        height: 320px;
        width: 280px;
    }
}

@media (max-width: 400px) {
    .About .ant-tabs-tab {
        font-size: 9px !important;
    }

    .About__Intro__Overlay-cancel {
        padding: 1rem 3rem;
        font-size: 18px;
    }
    
    .About__Intro__Overlay-cancel  > p:first-child {
        text-decoration-thickness: 2px;
        font-size: 60px;
    }

    .About__Content__Heading {
        padding: 7em 4em 7em;
    }

    .About__Content__Heading__Wrapper {
        font-size: 13px;
        line-height: 25px;
    }
    
    .About__Content__Heading__Wrapper > p:first-child {
        font-size: 26px;
    }

    .About__Content__Break {
        font-size: 30px;
    }

    .About__Content__Tabs-text {
        font-size: 11px;
        line-height: 25px;
    }
}

@media (max-width: 320px) {
    .About__Intro__Overlay-cancel {
        font-size: 13px;
    }
    
    .About__Intro__Overlay-cancel  > p:first-child {
        font-size: 50px;
    }

    .About__Content__Heading__Wrapper {
        font-size: 12px;
        line-height: normal;
    }
    
    .About__Content__Heading__Wrapper > p:first-child {
        font-size: 24px;
    }

    .About__Content__Break {
        font-size: 26px;
    }

    .About__Content__Tabs-text {
        font-size: 12px;
        line-height: normal;
    }
}