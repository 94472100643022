.title-orange {
    color: var(--brand-color-orange);
}

/* Verticale Timeline */
.vertical-timeline-element {
    color: black;   
}

.vertical-timeline-element-date {
    font-family: var(--font-quartary);
    font-weight: 900 !important;
}

.vertical-timeline-element-content {
    background: transparent !important;
}

.vertical-timeline-element-icon {
    background: var(--brand-color-green);
}

.Team__Content__Timeline-wrapper::before {
    background: var(--brand-color-mid-green) !important;
}

/* Content */
.Team {
    color: white;
    padding: 5rem 0 0 0;
    z-index: 1;
    position: relative;
    height: 100%;
}

.Team__Content {
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: left;
    padding: 1em 5em;
    margin-bottom: 3em;
}

.Team__Content__Text {
    color: black;
    margin-bottom: 5rem;
    font-size: 16px;
}

.Team__Content__Custom-background {
    color: white;
    padding: 8rem 5rem;
    text-align: center;
}

.Team__Content__Custom-background-second {
    background-color: var(--brand-color-white);
    color: black;
    padding: 5rem 5rem;
}

.Team__Content__Second-background {
    padding-top: 6rem;
    background: white;
    margin-bottom: 0;
}

.Team__Content__Title-second {
    color: var(--brand-color-green);
}

.Team__Content__Title {
    font-family: var(--font-primary);
    font-size: 80px;
}

/* .Team__Content__Title-second {
    color: var(--brand-color-orange);
} */

.Team__Content__Title-sub {
    font-size: 25px;
    font-family: var(--font-secondary);
}

.Team__Content__Custom-title-sub {
    font-family: var(--font-secondary);
    font-weight: 400;
}

.Team__Content__Images {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
}

.Team__Content__Images-ssm {
    width: 300px;
}

.Team__Content__Timeline-element {
    /* font-family: var(--font-secondary); */
}

@media (max-width: 1250px) {
    .Team__Content__Title {
        font-size: 82px;
    }
    
    .Team__Content__Images {
        flex-direction: column;
        align-items: center;
        justify-content: center;
    }

    .Team__Content__Images > img:first-child {
        margin-top: 3em;
    }

    .Team__Content__Images > img:nth-of-type(2) {
        margin: 5em 0 7em 0;
    }
}

@media (max-width: 1000px) {
    .Team__Content__Title {
        font-size: 70px;
    }

    .Team__Content__Title-sub {
        font-size: 22px;
    }

    .Team__Content__Second-background {
        padding-top: 5rem;
        background: white;
        margin-bottom: 0;
    }
}

@media (max-width: 780px) {
    .Team {
        padding: 0;
    }

    .Team__Content__Title {
        font-size: 60px;
    }

    .Team__Content__Title-sub {
        font-size: 20px;
    }

    .Team__Content__Images img {
        width: 200px;
    }

    .Team__Content__Title-second {
        font-size: 40px;
        margin-bottom: 1.5rem;
    }
}

@media (max-width: 600px) {
    .Team__Content__Title {
        font-size: 48px;
    }

    .Team__Content__Title-sub {
        font-size: 18px;
    }

    .Team__Content__Images img {
        width: 160px;
    }

    .Team__Content__Title-second {
        font-size: 35px;
    }
}

@media (max-width: 400px) {
    .Team > p:first-child {
        font-size: 60px;
    }

    .Team__Content {
        padding-left: 3em;
        padding-right: 3em;
    }

    .Team__Content__Title {
        font-size: 40px;
    }

    .Team__Content__Title-sub {
        font-size: 16px;
    }

    .Team__Content__Images img {
        width: 160px;
    }

    .Team__Content__Title-second {
        font-size: 30px;
    }
}

@media (max-width: 320px) {
    .Team > p:first-child {
        font-size: 50px;
    }
    
    .Team__Content {
        padding-left: 2em;
        padding-right: 2em;
    }

    .Team__Content__Title {
        font-size: 30px;
    }

    .Team__Content__Title-sub {
        font-size: 14px;
    }
}